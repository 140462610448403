<template>
  <div>
    <slot />
    <a-modal
      title="Billing Address"
      :visible="visible"
      @ok="onConfirm"
      okText="Save"
      :confirmLoading="loading"
      @cancel="$emit('close')"
    >
      <a-form :form="form" @submit.prevent.native="onConfirm">
        <a-form-item label="Business Name" :colon="false" required>
          <a-input
            v-decorator="['name', {
              validate: [{
                trigger: 'change',
                rules: [{ required: true, message: 'Name is required.' }],
              }]
            }]"
            name="name"
            type="text"
          />
        </a-form-item>
        <a-form-item :colon="false" required>
          <a-input
            v-decorator="['address.line_1', {
              validate: [{
                trigger: 'change',
                rules: [{ required: true, message: 'Line 1 is required.' }],
              }]
            }]"
            name="line_1"
            placeholder="Line 1 (e.g. street, PO box)"
            type="text"
          />
        </a-form-item>
        <a-form-item :colon="false" required>
          <a-input
            v-decorator="['address.line_2', {
              validate: [{
                trigger: 'change',
              }]
            }]"
            name="line_2"
            placeholder="Line 2 (e.g. apartment, suite, building)"
            type="text"
          />
        </a-form-item>
        <a-form-item :colon="false" required>
          <a-input
            v-decorator="['address.city', {
              validate: [{
                trigger: 'change',
                rules: [{ required: true, message: 'City is required.' }],
              }]
            }]"
            name="city"
            placeholder="City or Town"
            type="text"
          />
        </a-form-item>
        <div class="row">
          <div class="col-12 col-lg-6">
            <a-form-item :colon="false" required>
              <a-input
                v-decorator="['address.postal_code', {
                  validate: [{
                    trigger: 'change',
                    rules: [{ required: true, message: 'Postal code is required.' }],
                  }]
                }]"
                name="postal_code"
                placeholder="Postal Code"
                type="text"
              />
            </a-form-item>
          </div>
          <div class="col-6">
            <a-form-item :colon="false">
              <a-input
                v-decorator="['address.state']"
                name="state"
                placeholder="State"
                type="text"
              />
            </a-form-item>
          </div>
        </div>
        <a-form-item label="Country" :colon="false" required>
          <a-select
            v-decorator="['address.country_code', {
              validate: [{
                trigger: 'change',
                rules: [{ required: true, message: 'Country is required.' }],
              }]
            }]"
            name="country_code"
            :filterOption="filterOption"
            placeholder="Select a country"
            showSearch
          >
            <a-select-option v-for="(name, code) in $store.state.countries" :key="code" :value="code">
              {{ name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="EU VAT ID" :colon="false">
          <a-input
            v-decorator="['vat_id']"
            name="vat_id"
            type="text"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
    }
  },

  created() {
    this.form = this.$form.createForm(this, {
      mapPropsToFields: () => {
        return {
          name: this.$form.createFormField({ value: this.$store.state.user.name }),
          address: {
            line_1: this.$form.createFormField({ value: this.$store.state.user.address.line_1 }),
            line_2: this.$form.createFormField({ value: this.$store.state.user.address.line_2 }),
            city: this.$form.createFormField({ value: this.$store.state.user.address.city }),
            postal_code: this.$form.createFormField({ value: this.$store.state.user.address.postal_code }),
            state: this.$form.createFormField({ value: this.$store.state.user.address.state }),
            country_code: this.$form.createFormField({ value: this.$store.state.user.address.country_code }),
          },
          vat_id: this.$form.createFormField({ value: this.$store.state.user.vat.number }),
        }
      },
    })
  },

  methods: {
    onConfirm() {
      this.form.validateFields(async (err, values) => {
        try {
          if (err) return false
          this.loading = true

          var { data } = await this.$httpd.put(`/user`, values)
        } catch (err) {
          if (err.response) {
            this.$message.error(err.response.data.message)
          } else {
            this.$message.error(err.message)
          }

          return false
        } finally {
          this.loading = false
        }

        this.$store.commit('user', data)
        this.$message.success('Account has been updated.')
        this.$emit('success')
      })
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  },
}
</script>
