var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("default"),_c('a-modal',{attrs:{"title":"Billing Address","visible":_vm.visible,"okText":"Save","confirmLoading":_vm.loading},on:{"ok":_vm.onConfirm,"cancel":function($event){return _vm.$emit('close')}}},[_c('a-form',{attrs:{"form":_vm.form},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.onConfirm.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":"Business Name","colon":false,"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            validate: [{
              trigger: 'change',
              rules: [{ required: true, message: 'Name is required.' }],
            }]
          }]),expression:"['name', {\n            validate: [{\n              trigger: 'change',\n              rules: [{ required: true, message: 'Name is required.' }],\n            }]\n          }]"}],attrs:{"name":"name","type":"text"}})],1),_c('a-form-item',{attrs:{"colon":false,"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address.line_1', {
            validate: [{
              trigger: 'change',
              rules: [{ required: true, message: 'Line 1 is required.' }],
            }]
          }]),expression:"['address.line_1', {\n            validate: [{\n              trigger: 'change',\n              rules: [{ required: true, message: 'Line 1 is required.' }],\n            }]\n          }]"}],attrs:{"name":"line_1","placeholder":"Line 1 (e.g. street, PO box)","type":"text"}})],1),_c('a-form-item',{attrs:{"colon":false,"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address.line_2', {
            validate: [{
              trigger: 'change',
            }]
          }]),expression:"['address.line_2', {\n            validate: [{\n              trigger: 'change',\n            }]\n          }]"}],attrs:{"name":"line_2","placeholder":"Line 2 (e.g. apartment, suite, building)","type":"text"}})],1),_c('a-form-item',{attrs:{"colon":false,"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address.city', {
            validate: [{
              trigger: 'change',
              rules: [{ required: true, message: 'City is required.' }],
            }]
          }]),expression:"['address.city', {\n            validate: [{\n              trigger: 'change',\n              rules: [{ required: true, message: 'City is required.' }],\n            }]\n          }]"}],attrs:{"name":"city","placeholder":"City or Town","type":"text"}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('a-form-item',{attrs:{"colon":false,"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address.postal_code', {
                validate: [{
                  trigger: 'change',
                  rules: [{ required: true, message: 'Postal code is required.' }],
                }]
              }]),expression:"['address.postal_code', {\n                validate: [{\n                  trigger: 'change',\n                  rules: [{ required: true, message: 'Postal code is required.' }],\n                }]\n              }]"}],attrs:{"name":"postal_code","placeholder":"Postal Code","type":"text"}})],1)],1),_c('div',{staticClass:"col-6"},[_c('a-form-item',{attrs:{"colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address.state']),expression:"['address.state']"}],attrs:{"name":"state","placeholder":"State","type":"text"}})],1)],1)]),_c('a-form-item',{attrs:{"label":"Country","colon":false,"required":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address.country_code', {
            validate: [{
              trigger: 'change',
              rules: [{ required: true, message: 'Country is required.' }],
            }]
          }]),expression:"['address.country_code', {\n            validate: [{\n              trigger: 'change',\n              rules: [{ required: true, message: 'Country is required.' }],\n            }]\n          }]"}],attrs:{"name":"country_code","filterOption":_vm.filterOption,"placeholder":"Select a country","showSearch":""}},_vm._l((_vm.$store.state.countries),function(name,code){return _c('a-select-option',{key:code,attrs:{"value":code}},[_vm._v(" "+_vm._s(name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"EU VAT ID","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['vat_id']),expression:"['vat_id']"}],attrs:{"name":"vat_id","type":"text"}})],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }