<template>
  <div>
    <div class="card mb-3">
      <div class="card-header d-flex justify-content-between">
        <div>
          <strong>Dashboard</strong>
        </div>
        <div>
          <a href="https://vatstack.com/docs" target="_blank">API Reference <i class="fal fa-external-link ml-1"></i></a>
        </div>
      </div>
      <div class="card-body">
        <p><strong>Welcome to your Vatstack dashboard!</strong></p>
        To implement the VAT API within minutes, we’ve prepared a <a href="https://vatstack.com/docs/getting-started">quick start guide</a> for you. You can also find all available endpoints documented in our API reference.
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-header">
        <strong>Active Tax Regions ({{ $store.state.user.regions.length }})</strong>
      </div>
      <div class="card-body">
        <div class="row">
          <template v-for="region in regions">
            <div v-if="$store.state.user.regions.find(item => item.type === region.id)" class="col-12 col-md-6">
              <router-link :to="{ name: 'regions-id', params: { id: $store.state.user.regions.find(item => item.type === region.id).id }}">
                <div class="bg-light text-dark rounded p-3 mb-3">
                  <img width="24" height="24" :src="require(`@/assets/images/countries/${ region.flag }.svg`)" class="mr-2" style="margin-top: -4px;"> {{ region.name }}
                </div>
              </router-link>
            </div>
          </template>
        </div>
      </div>
      <div class="card-footer">
        <a-button type="primary" @click="$router.push({ name: 'regions' })">
          Manage Tax Regions
        </a-button>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-6">
            <strong>Business Location</strong>
          </div>
          <div class="col-6 text-right">
            <AddressChange :visible="visible" @close="visible = false" @success="onSuccess">
              <a-button size="small" @click="visible = true">
                <i class="fal fa-pen mr-2"></i> Change
              </a-button>
            </AddressChange>
          </div>
        </div>
      </div>
      <div class="card-body">
        <dl class="row">
          <dt class="col-12 col-lg-3">
            <label class="label">Business Name</label>
          </dt>
          <dd class="col-12 col-lg-9">
            {{ $store.state.user.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-12 col-lg-3">
            <label class="label">Address</label>
          </dt>
          <dd class="col-12 col-lg-9">
            <div>{{ $store.state.user.address.line_1 }}</div>
            <div>{{ $store.state.user.address.line_2 }}</div>
            <div>{{ $store.state.user.address.city }} {{ $store.state.user.address.state }} {{ $store.state.user.address.postal_code }}</div>
          </dd>
        </dl>
        <dl class="row mb-0">
          <dt class="col-12 col-lg-3">
            <label class="label">Country</label>
          </dt>
          <dd class="col-12 col-lg-9">
            {{ $store.state.countries[$store.state.user.address.country_code] }}
          </dd>
        </dl>
      </div>
    </div>
    <section class="text-right">
      <router-link :to="{ name: 'delete' }"><small class="text-danger">Delete Account</small></router-link>
    </section>
  </div>
</template>

<script>
import AddressChange from '@/components/Address/AddressChange'

export default {
  components: {
    AddressChange,
  },

  data() {
    return {
      visible: false,
    }
  },

  computed: {
    regions() {
      return [
        { id: 'au_gst', name: 'Australia', flag: 'AU' },
        { id: 'eu_vat', name: 'European Union', flag: 'EU' },
        { id: 'no_vat', name: 'Norway', flag: 'NO' },
        { id: 'ru_vat', name: 'Russia', flag: 'RU' },
        { id: 'ch_vat', name: 'Switzerland', flag: 'CH' },
        { id: 'gb_vat', name: 'United Kingdom', flag: 'GB' },
      ]
    },
  },

  methods: {
    onSuccess() {
      this.visible = false
      this.$emit('change')
    },
  },
}
</script>
